import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Toronto: Get Ready to Meet Your Smile Stylist | Top Cosmetic Dentist" 
    description="USA based Celebrity Cosmetic Dentist is ready to introduce his Hollywood Smiles to Toronto’s elite and provide a new standard in porcelain veneers and cosmetic dentistry." 
    keywords="Cosmetic Dentist" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="news-press hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="pr__cont">
              <h1>Canada: Get Ready to Meet Your Smile Stylist</h1>
              <h2>DDS and Best-Selling Author Dr. Kaloti to Start Designing Smiles at New Ontario Location</h2>
            </div>

          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="news-press hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h2>Canada: Get Ready to Meet Your Smile Stylist</h2>
            <h2>DDS and Best-Selling Author Dr. Kaloti to Start Designing Smiles at New Ontario Location</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <h2>Canadian smiles just got a little more glamorous.</h2>
            <p className="lead">Florida-based cosmetic and general dentist Dr. Hisham Kaloti is bringing the Smile Stylist to Canada, and is ready to begin transforming Canadian smiles. Originating from the Jacksonville, FL Smile Stylist enterprise; Dr. Kaloti has been practicing cosmetic dentistry for over 18 years – earning his Canadian Smile Stylist designation deservedly.</p>
            <img src={ require("../../images/desktop/_pressrelease/pics/canadian-miles.jpg")} className="img-thumbnail img-news" />
            <p>“We’re super thrilled to be able to bring our expertise to Canada,” Dr. Kaloti shares. “Our first location will be Oakville Lakeside Dental in beautiful downtown Oakville because of their advanced technology and patient-focused philosophy, and we plan on expanding into other major Canadian cities in the near future – with Toronto being a priority.”</p>
            <p>The Smile Stylist has an impressive portfolio of smiles that he’s worked to cosmetically enhance during the course of his profession. This includes celebrities like Gretchen Rossi of the Real Housewives of Orange County, Peta Murgatroyd of Dancing with the Stars, and Toronto’s very own Kat Callaghan of Z103.5.</p>
            <p>Indisputably, precision and accuracy are key components of a successful cosmetic dental treatment. With that said, the Smile Stylist depends on DSD (Digital Smile Design) technology to assist in treatment planning for simple to complex cosmetic cases.</p>
            <p>“The DSD essentially assures myself and the patient that they’ll be getting the exact smile they’re looking for,” Dr. Kaloti explains. “We depend on this technology every time we begin a new treatment case. By incorporating facial features, lips, even side profiles and using the 3D digital X-rays – we can custom design the perfect smile for every patient. Every smile is unique and will require a different series of steps in order to achieve the desired result. The digital smile design allows our patients to co-author their new smile before their treatment is complete.”</p>
            <p>The Smile Stylist is now open for business at their new Oakville location and is ready to consult with people in the greater Toronto area who are looking to enhance their smile.</p>
            <p>“A new and attractive smile can be truly life-changing for a patient,” says Dr. Kaloti. “What we do here is so rewarding. When a person is fully confident in their smile, that confidence stays with them 24/7… and it can open them up to endless possibilities. We’re so ready to bring our smile styling to the beautiful and discerning people of Canada!”</p>
            <p>To learn more about the Smile Stylist TO’s cosmetic dentistry services, please contact:</p>
            <p>
              <strong>The Smile Stylist</strong><br />
              123 Trafalgar Rd, Oakville, ON L6J 3G4, Canada<br />
              info@thesmilestylist.ca<br />
              (905) 849-0558
            </p>
          </div>
          <div className="col-sm-4 blog-right">
            <img src={ require("../../images/desktop/services/press.png")} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage